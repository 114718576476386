<template>
  <section class="w-full">
    <header-register
      :showButtonView="(previewButtonIsVisible = false)"
      @register="handleActivePageOperation"
      >{{ $t("sectionPbm.titlePbm") }}
    </header-register>
    <main class="w-full">
      <ManagePbmEdit />
    </main>
  </section>
</template>

<script type="module">
import HeaderRegister from "@core/components/register/HeaderRegister.vue";
import ManagePbmEdit from "@/components/pbm/components/edit/ManagePbmEdit.vue";

export default {
  name: "PbmEdit",
  components: {
    HeaderRegister,
    ManagePbmEdit,
  },
};
</script>
