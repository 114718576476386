<template>
  <div class="w-full flex flex-col justify-between">
    <section class="-mt-4">
      <b-tabs class="w-full">
        <b-tab title="Geral">
          <GeneralPbmInformation class="mt-8" :viewModel="viewModel" />
        </b-tab>
      </b-tabs>
    </section>
    <div class="flex">
      <span class="text-red-600"
        >Os campos com * são de preenchimento obrigatório</span
      >
    </div>
    <footer class="w-full flex items-center justify-start gap-5 my-10">
      <Button
        class="bg-green-500 shadow shadow-[rgba(200, 200, 200, 0.7)] hover:bg-green-600 transition-all duration-300"
        buttonText="Confirmar"
        :onClick="Edit"
      />
      <Button
        class="bg-red-500 shadow shadow-[rgba(200, 200, 200, 0.7)] hover:bg-red-600 transition-all duration-300"
        buttonText="Cancelar"
        :onClick="redirectUrl"
      />
    </footer>
    <Modal
      v-if="modalIsOpen"
      :modalIsOpen.sync="modalIsOpen"
      :closeModal="closeModal"
      :modalText="$t('commons.modalTextEdit')"
    />
    <Modal
      v-if="modalError"
      :modalIsOpen.sync="modalError"
      :closeModal="closeModal"
      :modalText="$t('commons.modalErrorCreate')"
    />
    <Modal
      v-if="modalErroSalvar"
      :modalIsOpen.sync="modalErroSalvar"
      :closeModal="closeModal"
      :modalText="$t('commons.modalErroSalvar')"
    />
  </div>
</template>

<script>
import { BTabs, BTab } from "bootstrap-vue";

import GeneralPbmInformation from "./GeneralPbmInformation";
import Button from "@core/components/commons/button/Button";
import Modal from "@core/components/commons/modal/Modal";

export default {
  name: "ManagePbmEdit",
  components: {
    BTabs,
    BTab,
    GeneralPbmInformation,
    Button,
    Modal,
  },
  data() {
    return {
      modalIsOpen: false,
      modalError: false,
      modalErroSalvar: false,
      viewModel: {
        Id: null,
        ContaId: null,
        IntegracaoId: null,
        Nome: null,
        Observacao: null,
      },
    };
  },
  created() {
    this.RecuperarPbm(this.$router.currentRoute.params.id);
  },
  methods: {
    openModal() {
      this.modalIsOpen = true;
    },
    closeModal(event) {
      if (event.target === event.currentTarget) {
        this.modalIsOpen = false;
        this.modalError = false;
        this.modalErroSalvar = false;
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    },
    RecuperarPbm(id) {
      this.$http({
        url: `/pbm/obter/${id}`,
        method: "Get",
      }).then((response) => {
        this.viewModel = {
          Nome: response.data.nome,
          Id: response.data.id,
          ContaId: response.data.contaId,
          IntegracaoId: response.data.integracaoId,
          Observacao: response.data.observacao,
        };
      });
    },
    Edit() {
      if (this.Validate()) {
        this.$http({
          url: `/pbm/editar`,
          data: this.viewModel,
          method: "POST",
        })
          .then(() => {
            this.modalIsOpen = true;
            setTimeout(() => this.$router.push({ name: "pbm" }), 3000);
          })
          .catch(() => (this.modalErroSalvar = true));
      }
    },
    Validate() {
      if (this.viewModel.Nome == null || this.viewModel.Nome == "") {
        this.modalError = true;
        return false;
      }
      return true;
    },
    redirectUrl() {
      return this.$router.push("/pbm");
    },
  },
};
</script>

<style></style>
