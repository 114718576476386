<template>
  <section>
    <b-form>
      <!-- Nome -->
      <div class="grid grid-cols-1 md:grid-cols-12 gap-4">
        <div class="col-span-8">
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12 sm:col-span-6">
              <input-required
                v-model="viewModel.Nome"
                :label="$t('sectionPbm.namePbm')"
                :rules="{ required: true, max: 100 }"
                max="100"
              />
            </div>
          </div>
        </div>
        <!-- Observação -->
        <div class="col-span-8 border-solid border-b border-gray-300 pb-2">
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12 sm:col-span-6">
              <input-default
                v-model="viewModel.Observacao"
                :label="$t('sectionPbm.observationPbm')"
                :rules="{ min: 1, max: 250 }"
                max="250"
              />
            </div>
          </div>
        </div>
      </div>
    </b-form>
  </section>
</template>

<script>
import { required } from "@validations";
import InputRequired from "@core/components/commons/inputs/InputRequired";
import InputDefault from "@core/components/commons/inputs/InputDefault";

import { BForm } from "bootstrap-vue";

export default {
  components: {
    BForm,
    InputRequired,
    InputDefault,
  },
  props: {
    viewModel: {
      type: Object,
    },
  },
  data() {
    return {
      required,
    };
  },
  created() {},
  methods: {},
};
</script>
